.avatar {
  height: 100%;
  width: 100%;
  contain: content;
  max-height: 100%;
  max-width: 100%;
  aspect-ratio: 1/1;

  img {
    height: 100%;
    width: 100%;
    contain: content;
    border-radius: 50%;
    object-fit: cover;
  }

  &.premium {
    img {
      border-width: 0.1rem;
      border-style: solid;
      padding: 0.1rem;
      border-color: var(--funkerCommentsProIconColor);
    }
  }
}

.funkerButton {
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0.25em 0.5rem;
  border-radius: 0.3rem;
  background-color: var(--Color1);
  border: solid 0.1rem var(--Color3);
  cursor: pointer;

  &.disabledButton {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.alternateButton {
    background-color: var(--Color2);
  }
}

.buttonLink {
  text-decoration: none;
  color: inherit;
}

.testing {
  padding: 1rem;
  justify-content: center;
  justify-items: center;
  grid-template-columns: 1fr;
}

.form {
  border: solid 0.1rem var(--Color1);
  grid-template-rows: min-content min-content min-content 1fr;
  width: 100%;
  max-width: 50rem;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;

  .formTitle {
    padding: 0.5rem;
    text-align: center;
    font-size: 2rem;
  }

  .formFields {
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-template-columns: max-content 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    padding: 1rem;
    max-height: 100%;
    overflow-y: auto;
    border-top: solid 0.1rem var(--Color1);

    .formFieldLabel {
      font-size: 1.2rem;
      display: flex;
      column-gap: 0.5rem;

      &.required::after {
        content: "*";
        color: var(--Color1);
      }
    }

    .formFieldInput {
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;
      &.file {
        input {
          width: 100%;
        }
      }
      &.required {
        border: solid 0.1rem var(--Color1);
      }
      &.textarea {
        width: 100%;
        height: 10rem;
        overflow-y: auto;
      }
      &.disabled {
        color: white;
        pointer-events: none;
        border: none;
        background-color: var(--BackGround2);
      }
    }
    .withIcon {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: max-content min-content;
      column-gap: 0.5rem;
      align-items: center;
    }

    .imagePreview {
      height: 11rem;
      max-width: 20rem;
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;
      border: solid 0.1rem var(--BackGround2);
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .formMessage {
    font-size: 2rem;
    font-weight: bold;
    justify-content: center;
    text-align: center;
    min-height: 3rem;
    max-height: 10rem;
    overflow-y: auto;
    padding: 0.5rem;

    &.formError {
      color: var(--Color1);
    }
  }

  .formButtons {
    grid-auto-flow: column;
    justify-content: space-between;
    column-gap: 1rem;
    padding: 1rem;
  }
}

.funkerTable {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  background-color: var(--SiteColor4);
  overflow-y: scroll;
  overflow-x: auto;
  scrollbar-gutter: stable;

  &.funkerSubTable {
    grid-row: 2;
    grid-column: 2/-1;
    border: 0.1rem solid rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    border-left: 0.1rem solid rgba(0, 0, 0, 0.5);
  }

  .funkerTableItem {
    display: grid;
    grid-auto-flow: column;

    &.header {
      position: sticky;
      top: 0;
      font-weight: bold;
      background-color: var(--Color2);
      box-shadow: none;
      border-bottom: solid 0.1rem var(--Color1);

      .funkerTableItemCell {
        grid-auto-flow: column;
        grid-template-columns: max-content min-content;
        cursor: default;

        &.withPointer {
          cursor: pointer;
        }

        &:hover {
          @media (pointer: fine) {
            font-weight: 900;
          }
        }
      }

      &:hover {
        @media (pointer: fine) {
          background-color: var(--Color2);
        }
      }
    }

    &:nth-child(2n) {
      background-color: rgba(0, 0, 0, 0.2);
    }

    &.pagingRow {
      position: absolute;
      bottom: 0;
      background-color: var(--Color2);
      font-weight: bold;
      border-top: solid 0.1rem var(--Color1);
      padding: 0.5rem;
      justify-content: center;
      align-content: center;
      column-gap: 0.5rem;
      width: calc(100% - 15.75rem);
      justify-self: flex-start;
    }

    &.totalRow {
      position: sticky;
      bottom: 0;
      background-color: var(--BackGround2);
      font-weight: bold;

      &:hover {
        @media (pointer: fine) {
          background-color: var(--BackGround2);
        }
      }
    }

    &:hover {
      @media (pointer: fine) {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }

    &.highlighted {
      background-color: rgba(0, 0, 0, 0.7);
    }

    .funkerTableItemCell {
      grid-row: 1;
      display: grid;
      align-items: center;
      justify-items: center;
      justify-content: center;
      padding: 0.5rem 0.3rem;
      width: 100%;
      overflow-wrap: anywhere;

      &:nth-child(2n) {
        background-color: rgba(0, 0, 0, 0.2);
      }

      &.expandIcon {
        display: grid;
        align-self: center;
        justify-self: center;
        font-size: 1.5rem;
        cursor: pointer;
      }

      &.fullCell {
        height: 100%;
        width: 100%;
        padding: 0;
        justify-content: unset !important;
      }

      .activityContent {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: max-content 1fr;
        grid-gap: 0.5rem;
        align-items: center;
      }

      &.hideOnMobile {
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
    }
  }
}

.ad {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;

  img {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
  }
}

.pageSelector {
  grid-auto-flow: column;
  column-gap: 0.5rem;
  grid-auto-columns: min-content;
  justify-content: center;
  align-content: center;
  width: fit-content;
  justify-self: center;

  &.allDisabled {
    .pagingButton {
      cursor: default;
      background-color: var(--Color2);
      opacity: 0.4;

      &:hover {
        @media (pointer: fine) {
          background-color: var(--Color2);
        }
      }
    }
  }

  .pagingButton {
    height: 2.2rem;
    min-width: 2.2rem;
    padding: 0 0.3rem;
    border-radius: 0.3rem;
    font-size: 1.2rem;
    grid-auto-flow: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    cursor: pointer;
    user-select: none;

    &.active {
      color: var(--Color1);

      font-size: 1.7rem;
    }

    &.disabled {
      cursor: default;
      // background-color: var(--Color2);
      opacity: 0.2;

      &:hover {
        @media (pointer: fine) {
          color: unset;
        }
      }
    }

    &:hover {
      @media (pointer: fine) {
        color: var(--Color1);
      }
    }
  }
}

.shareModal {
  z-index: 200;
  position: absolute;
  &.hidden {
    display: none;
  }
  .modalScreenMask {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100vh;
    height: 100vh;
    width: 100vw;
    width: 100dvw;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1rem;

    &.hidden {
      display: none;
    }
    .modalContainer {
      height: fit-content;
      max-height: 100%;
      width: fit-content;
      max-width: 100%;
      background-color: var(--BackGround2);
      padding: 0;
      border-radius: 8px;
      border: solid 0.15rem var(--Color1);
      box-shadow: 0 0 8px 4px var(--BackGround2);
      grid-template-columns: 1fr;
      overflow: hidden;
      overflow: -moz-hidden-unscrollable;

      .modalHeader {
        max-width: 100%;
        height: min-content;
        padding: 1rem;
        grid-template-columns: 1fr min-content;
        grid-template-rows: min-content min-content;
        // border-bottom: solid 0.15rem var(--Color1);
        .modalName {
          padding-bottom: 1.5rem;
          text-align: center;
          font-size: 3rem;
          grid-column: 1 / span 2;
          grid-row: 1;
        }
        .close {
          cursor: pointer;
          justify-self: flex-end;
          padding: 0.5rem;
          height: min-content;
          width: min-content;
          grid-column: 2;
          grid-row: 1;
          .closeButton {
            color: var(--FontColor1);
            height: 3rem;
            width: 3rem;

            &:hover {
              @media (pointer: fine) {
                color: var(--Color1);
              }
            }
          }
        }
        .shareTitle {
          overflow: hidden;
          overflow: -moz-hidden-unscrollable;
          width: 100%;
          max-height: 10rem;
          font-size: 2rem;
          text-align: center;
          text-wrap: wrap;
          grid-row: 2;
          grid-column: 1 / span 2;
        }
      }

      .shareLink {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: auto;
        justify-items: center;
        justify-self: center;
        column-gap: 1rem;
        row-gap: 1rem;
        max-width: 100%;
        overflow: hidden;
        overflow: -moz-hidden-unscrollable;
        overflow-wrap: anywhere;
        padding: 1rem;
        // border-bottom: solid 0.15rem var(--Color1);

        .shareLinkText {
          width: 40rem;
          max-width: 100%;
          padding: 0.5rem;
          border-radius: 0.5rem;
          border: solid 0.15rem var(--Color1);
          background-color: transparent;
          color: var(--FontColor1);
        }
        .copyLinkButton {
          font-size: 1.5rem;
        }
      }

      .shareContainer {
        justify-self: center;
        justify-content: center;
        padding: 2rem;
        display: flex;
        column-gap: 2rem;
        row-gap: 2rem;
        flex-wrap: wrap;
        .shareButton {
          padding: 2rem 2rem;
          height: min-content;
          .shareIcon {
            height: 6rem;
            width: 6rem;
            @media (max-width: 768px) {
              height: 4rem;
              width: 4rem;
            }
          }
        }
      }
    }
  }
}

.redirectingNotice {
  padding: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: center;
  font-size: 1.5rem;
  .openingNotice {
    font-size: 2rem;
    font-weight: bold;
    padding: 1rem;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  position: relative;
  background-color: var(--BackGround1);
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 500px;
  width: 90%;
  min-height: 15dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  .modalTitle {
    padding: 0;
    margin: 0;
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--FontColor1);
  }

  .modalBody {
    margin: 0;
    color: var(--FontColor1);
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .modalInputField {
    font-size: 1rem;
    width: 100%;
    max-width: 100%;
    outline: none;

    label {
      display: block;
      font-size: 1.1rem;
      padding-bottom: 0.5rem;
      color: var(--FontColor1);
    }

    &::placeholder {
      color: var(--FontColor1);
      opacity: 0.7;
    }

    &:focus {
      border-color: var(--Color1);
      background-color: var(--BackGround1);
    }
  }

  .modalButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
  }

  .errorText {
    min-height: 1.25rem;
    display: block;
    color: var(--FontColor5);
    font-size: 0.9rem;
  }

  .modalCloseButton {
    position: absolute;
    top: 0.75rem;
    right: 1rem;
    background: transparent;
    border: none;
    font-size: 1.75rem;
    font-weight: bold;
    color: var(--FontColor1);
    cursor: pointer;
    padding: 0;
    line-height: 1;

    &:hover {
      color: var(--Color1);
    }
  }
}
